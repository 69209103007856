import { get } from './http'

export const subjects = () => {
  const url = '/myChapter/newSubjects'
  return get(url)
}

export const myCourse = (params) => {
  const url = '/myChapter'
  return get(url, params)
}

export const history = () => {
  const url = '/myChapter/history'
  return get(url)
}
